/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, FC, ReactElement, useMemo, Ref, UIEvent, MutableRefObject } from 'react';
import { dataAttributeProps, mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';
import { Option } from '../Option';
import ContextMenuIcon from './icon/ContextMenuIcon';
import { SelectListMenu } from './SelectListMenu';
import Button, { ButtonSize, ButtonType } from './form-control/Button';
import { ChevronIcon, ChevronType } from './icon/ChevronIcon';
import Tooltip from './Tooltip';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';

export type ContextMenuItem = {
  hide?: boolean;
  icon?: ReactElement;
  disabled?: boolean;
  disabledTooltip?: string;
} & (
  | {
      onClick: () => void;
      hasDivider?: false;
    }
  | {
      hasDivider: true;
    }
) &
  (
    | {
        title: string;
      }
    | {
        customRenderer: () => ReactElement;
      }
  );

type ContextMenuProps = {
  items: ContextMenuItem[];
  className?: string;
  size?: ButtonSize;
  children?: (ref: Ref<any>, isOpen: boolean) => ReactElement | HTMLElement;
} & ({ type?: 'button'; selectedButtonTitle: string } | { type?: 'icon'; selectedButtonTitle?: string });

const ContextMenu: FC<ContextMenuProps> = (props) => {
  const { items, className, type = 'icon', selectedButtonTitle, size = ButtonSize.M, children } = props;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('common');
  const menuId = useMemo(() => uuid(), []);

  const dataAttrs = dataAttributeProps(props);

  const toggleVisibility = (e: UIEvent<HTMLElement> | null = null): void => {
    e?.preventDefault();
    e?.stopPropagation();
    setOpen((prev) => !prev);
  };

  const onItemClick = (itemIndex: string): void => {
    toggleVisibility();
    const item = items.filter((item) => !item.hide)[parseInt(itemIndex, 10)];
    if (item && 'onClick' in item) {
      item.onClick();
    }
  };

  const options = useMemo(
    () =>
      items
        .filter((item) => !item.hide)
        .map((item, i) => ({ ...item, id: i.toString(), text: 'title' in item ? item.title : '', value: i.toString() })),
    [items],
  );

  const CustomListRenderer = useMemo(
    () =>
      function ContextMenuListRenderer(item: Option<string, string | number> & ContextMenuItem) {
        const { id } = item;

        if ('customRenderer' in item) return item.customRenderer();
        return (
          <Tooltip text={item.disabled ? item.disabledTooltip : ''}>
            {(tooltip) => (
              <div key={id} {...tooltip}>
                <div className={`-ml-2 inline-block pr-2 ${item.disabled ? 'cursor-default opacity-50' : ''}`}>{item.icon}</div> {item.title}
              </div>
            )}
          </Tooltip>
        );
      },
    [],
  );

  if (!options.length) {
    return null;
  }

  return (
    <SelectListMenu
      id={`context-menu-${menuId}`}
      onBlur={() => setOpen(false)}
      width={'!min-w-64'}
      options={options}
      isOpen={open}
      onClick={(option) => onItemClick(option.id)}
      customListItemRenderer={CustomListRenderer}
      style={{ maxWidth: 'none!important' }}
    >
      {(triggerProps) => (
        <div {...triggerProps} className={`relative ${className}`} {...dataAttrs}>
          {type === 'icon' && !children && (
            <button
              aria-haspopup="true"
              aria-controls={`context-menu-${menuId}`}
              aria-expanded={open}
              aria-label={t('aria-label.context-menu')}
              data-cy="context-menu"
              {...dataAttributeProps(props)}
              className="hover:border-primary-2 hover:bg-primary-2 relative cursor-pointer select-none rounded-md border border-transparent p-1 hover:bg-opacity-25"
              {...mouseAndKeyboardCallbackProps(toggleVisibility)}
            >
              <ContextMenuIcon className="h-4 w-4" />
            </button>
          )}
          {type === 'icon' && !!children && (
            <button
              aria-haspopup="true"
              aria-controls={`context-menu-${menuId}`}
              aria-expanded={open}
              aria-label={t('aria-label.context-menu')}
              data-cy="context-menu"
              {...dataAttributeProps(props)}
              {...mouseAndKeyboardCallbackProps(toggleVisibility)}
            >
              {children(triggerProps.ref as MutableRefObject<HTMLElement>, open)}
            </button>
          )}
          {type === 'button' && (
            <Button
              aria-haspopup="true"
              aria-controls={`context-menu-${menuId}`}
              aria-expanded={open}
              aria-label={t('aria-label.context-menu')}
              type={ButtonType.PRIMARY}
              size={size}
              onClick={toggleVisibility}
            >
              {selectedButtonTitle} <ChevronIcon type={ChevronType.DOWN} className="ml-2 h-5 w-5" />
            </Button>
          )}
        </div>
      )}
    </SelectListMenu>
  );
};

export default ContextMenu;
